import React, { useState } from "react";
import TableMain from "../../components/Common/table/TableMain";
import { getLastPage } from "../../utils/getLastPage";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function OrderReport() {
  const headers = [
    "No.",
    "Order ID",
    "Product Name",
    "Amount",
    "Quantity",
    "Total Amount",
    "Order Date",
  ];

  const location = useLocation();
  const { t } = useTranslation();

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dateKey, setDateKey] = useState(Date.now());
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [currentPage, setCurrentPage] = useState(1);

  const orderReport = ApiHook.CallOrderReport(
    currentPage,
    itemsPerPage,
    dateRange.startDate,
    dateRange.endDate,
    location?.state?.username ?? ""
  );

  const lastPage = getLastPage(
    itemsPerPage,
    orderReport?.data?.data?.data?.totalCount
  );

  const handleDateRange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [toDate, fromDate] = dates;
      setDateRange({
        startDate: toDate.format("YYYY-MM-DD H:m:s"),
        endDate: fromDate.format("YYYY-MM-DD H:m:s"),
      });
    }
  };

  return (
    <>
      {" "}
      <div className="page_head_top">{t("orderReport")}</div>
      <div className="filter_Section">
        <div className="row justify-content-between">
          <div className="col-md-3 payout-filter">
            <div className="right_search_div d-flex gap-1">
              <div className="order_report_username">
                <strong>{`Username : ${
                  location?.state?.username.toUpperCase() ??
                  loggedUser?.username.toUpperCase()
                }`}</strong>
              </div>{" "}
              <RangePicker
                key={dateKey}
                onChange={(dates) => handleDateRange(dates)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="table-responsive min-hieght-table">
            <TableMain
              headers={headers}
              data={orderReport?.data?.data?.data?.tableData}
              startPage={1}
              currentPage={currentPage}
              totalPages={lastPage}
              type={"orderReport"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
