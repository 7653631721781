//local
// const BASE_URL = "http://192.168.21.19:5000/node_api/";
// const DEFAULT_KEY = '4fb73212-a8fe-43d3-ad75-775ed272e279'
// const PAYPAL_CLIENT_ID = ""

//demo
const BASE_URL = "https://adminvenga.iossmlm.com/node_api/";
const DEFAULT_KEY = '4fb73212-a8fe-43d3-ad75-775ed272e279'
const PAYPAL_CLIENT_ID = ""

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }