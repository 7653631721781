import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatDate } from "../../utils/formateDate";

export default function Step({ data }) {
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const NoProfile = "/images/user-profile.png";
  const stepWidthInPixel = 100;
  const [selectedNode, setSelectedNode] = useState("");
  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };
  const handleMouseLeave = () => {
    setHoveredItemId("");
    // setSelectedNode("");
  };
  const handleNodeSelection = (value) => {
    setSelectedNode(value);
  };
  const popover = (
    <Popover id="popover">
      <div id="treeview_pop">
        <div className="card">
          <div className="card-img-top">
            <div className="card-img-top_img">
              <img
                src={
                  hoveredItemId?.profilePic
                    ? hoveredItemId?.profilePic
                    : NoProfile
                }
                alt="profile_photo"
              />
            </div>
            <h5 className="card-title">{hoveredItemId?.username}</h5>
            <p className="card-text">
              {hoveredItemId?.firstName
                ? hoveredItemId?.firstName
                : ""}
            </p>
          </div>
          <div className="card-body">
            <div className="user_detail_tabl">
              <table>
                <tbody>
                  {hoveredItemId?.toolTip &&
                    Object?.entries(hoveredItemId?.toolTip)?.map(
                      ([key, value]) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            {key === "joinDate" ? formatDate(value) : value}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            {hoveredItemId?.rankDetails && hoveredItemId?.rankDetails?.name && (
              <a
                className="btn btn-rank"
                style={{
                  backgroundColor: hoveredItemId?.rankDetails?.color,
                }}
              >
                {hoveredItemId?.rankDetails?.name}
              </a>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
  return (
    <>
      <div className="tree_view_content_section">
        <div className="body genealogy-body genealogy-scroll">
          <svg
            className="tree__background"
            style={{ height: "100%", width: "100%" }}
          >
            <pattern
              id="pattern-83098"
              x="6"
              y="0"
              width="10"
              height="10"
              patternUnits="userSpaceOnUse"
            >
              <circle cx="0.4" cy="0.4" r="0.4" fill="#81818a"></circle>
            </pattern>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#pattern-83098)"
            ></rect>
          </svg>
          <div className="table-view-sec">
            <div className="container">
              <div className="stair_step_parent_dv">
                <div className="stair_step_parent_left">
                  <div className="row">
                    {data &&
                      data.map((step) => {
                        return (
                          <div>
                            <div
                              id={step.rankId}
                              key={step.rankId}
                              className="stair_step_cnt_row"
                            >
                              {step.users.length <= 0 ? (
                                <div>
                                  <a>
                                    <div>
                                      <div
                                        className="table-div"
                                        style={{
                                          width: `${
                                            step.width * stepWidthInPixel
                                          }px`,
                                        }}
                                      >
                                        <div className="table_active"> </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ) : (
                                step.users.map((user) => {
                                  return (
                                    <a
                                      onMouseEnter={() => handleItemHover(user)}
                                      onMouseLeave={() => handleMouseLeave()}
                                    >
                                      <div>
                                        <OverlayTrigger
                                          trigger={["hover", "focus"]}
                                          placement="right"
                                          overlay={popover}
                                        >
                                          <div
                                            className="table-div"
                                            id={`${user?.username}`}
                                            style={{
                                              width:
                                                selectedNode === user?.username
                                                  ? stepWidthInPixel
                                                  : `${
                                                      (step.width *
                                                        stepWidthInPixel) /
                                                      step.users.length
                                                    }px`,
                                            }}
                                            // onClick={() =>
                                            //   handleNodeSelection(user.username)
                                            // }
                                            /* the onclick "handleNodeSelection" will select users component and extend it to 100px in width */
                                          >
                                            <div className="table_active">
                                              {user?.username}
                                            </div>
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    </a>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="stair_step_parent_right">
                  <div className="side-panel">
                    <div className="row">
                      {data &&
                        data.map((step) => {
                          return (
                            <>
                              <div className="stair_step_cnt_row">
                                <div>
                                  <p>{step.step_name}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
