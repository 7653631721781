import API from "../../api/api";

export const ReportService = {
  getOrderReport: async ({
    page,
    itemsPerPage,
    startDate,
    endDate,
    username,
  }) => {
    const response = API.get(
      `order-report?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&username=${username}`
    );
    return response;
  },
};
