import React from "react";
import Step from "./Step";
import { ApiHook } from "../../hooks/apiHook";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";

export default function MainStepView() {
  const stepViewData = ApiHook.CallStepView();
  return (
    <>
      <TreeViewNavbar menu={"step-view"} />
      {/* <div>MainStepView</div> */}
      {stepViewData && <Step data={stepViewData} />}
    </>
  );
}
