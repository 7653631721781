import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
const BankDetailsTab = ({ bank }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // const {
  //   register,
  //   watch,
  //   trigger,
  //   formState: { errors },
  // } = useForm({
  //   bankName: bank?.bankName ?? "",
  //   branchName: bank?.branchName ?? "",
  //   holderName: bank?.holderName ?? "",
  //   accountNo: bank?.accountNo ?? "",
  //   ifsc: bank?.ifsc ?? "",
  //   pan: bank?.pan ?? "",
  // });
  const {
    register,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bankName: bank?.bankName ?? "",
      // branchName: bank?.branchName ?? "",
      // holderName: bank?.holderName ?? "",
      accountNo: bank?.accountNo ?? "",
      // ifsc: bank?.ifsc ?? "",
      // pan: bank?.pan ?? "",
      eBankerCode: bank?.eBankerCode ?? "",
      accountType: bank?.accountType ?? "",
    },
  });
  console.log("bank === ", bank);
  const formValues = watch();
  console.log("formvaluies --- ", formValues);
  const accounTypes = [
    { value: 1, label: `${t("savings")}` },
    { value: 2, label: `${t("current")}` },
  ];
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const updateBankMutation = ApiHook.CallUpdateBankDetails(formValues);

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };
  const changeBank = (data) => {
    setValue("bankName", data?.value);
    setValue("eBankerCode", bank.eBankerCodes[`${data.value}`]);
  };
  const changeAccounType = (data) => {
    setValue("accountType", data?.value);
  };
  const handleSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      updateBankMutation.mutate(formValues, {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          toast.success(res?.data);
          setIsEditModeEnabled(false);
        },
      });
    }
  };

  return (
    <div id="thirdTab" className="tabcontent">
      <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("bankDetails")}</h3>
      <div className="tabcontent_form_section">
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="bankName"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("bankName")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            {/* <input
              {...register("bankName", {
                required: t("this_field_is_required"),
              })}
              name="bankName"
              type="text"
              className="form-control"
              id="bankName"
              defaultValue={formValues?.bankName}
              disabled={!isEditModeEnabled}
            /> */}
            <Select
              {...register("bankName")}
              id="3"
              value={bank.bankListOptions.find(
                (item) => item.value === formValues?.bankName
              )}
              isDisabled={!isEditModeEnabled}
              isSearchable={false}
              options={bank.bankListOptions}
              onChange={changeBank}
            />
            {errors.bankName && (
              <span className="validation-error-message">
                {errors.bankName.message}
              </span>
            )}
          </div>
        </div>
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="eBankerCode"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("eBankerCode")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("eBankerCode", {
                required: t("this_field_is_required"),
              })}
              name="eBankerCode"
              type="text"
              className="form-control"
              id="eBankerCode"
              defaultValue={formValues?.eBankerCode}
              value={bank.eBankerCodes[`${formValues.bankName}`]}
              disabled={true}
            />
            {errors.branchName && (
              <span className="validation-error-message">
                {errors.branchName.message}
              </span>
            )}
          </div>
        </div>
        {/* <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="accountHolder"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("accountHolder")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("holderName", {
                required: t("this_field_is_required"),
              })}
              name="holderName"
              type="text"
              className="form-control"
              id="accountHolder"
              defaultValue={formValues?.holderName}
              disabled={!isEditModeEnabled}
            />
            {errors.holderName && (
              <span className="validation-error-message">
                {errors.holderName.message}
              </span>
            )}
          </div>
        </div> */}
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="accountNumber"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("accountNumber")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("accountNo", {
                required: t("this_field_is_required"),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("only_number"),
                },
              })}
              name="accountNo"
              type="text"
              className="form-control"
              id="accountNumber"
              defaultValue={formValues?.accountNo}
              disabled={!isEditModeEnabled}
            />

            {errors.accountNo && (
              <span className="validation-error-message">
                {errors.accountNo.message}
              </span>
            )}
          </div>
        </div>
        {/* <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="ifscCode"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("ifscCode")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("ifsc", {
                required: t("this_field_is_required"),
              })}
              name="ifsc"
              type="text"
              className="form-control"
              id="ifscCode"
              defaultValue={formValues?.ifsc}
              disabled={!isEditModeEnabled}
            />
            {errors.ifsc && (
              <span className="validation-error-message">
                {errors.ifsc.message}
              </span>
            )}
          </div>
        </div> */}
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="accountType"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("accountType")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            {/* <input
              {...register("pan", {
                required: t("this_field_is_required"),
              })}
              name="pan"
              type="text"
              className="form-control"
              id="pan"
              defaultChecked={formValues?.pan}
              defaultValue={formValues?.pan}
              disabled={!isEditModeEnabled}
            /> */}
            <Select
              {...register("accountType")}
              id="4"
              value={accounTypes.find(
                (item) => item.value === formValues?.accountType
              )}
              isDisabled={!isEditModeEnabled}
              isSearchable={false}
              options={accounTypes}
              onChange={changeAccounType}
            />

            {errors.accountType && (
              <span className="validation-error-message">
                {errors.accountType.message}
              </span>
            )}
          </div>
        </div>
        <div
          className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
        >
          <SubmitButton
            className="btn"
            isSubmitting={!isEditModeEnabled}
            text="update"
            click={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetailsTab;
